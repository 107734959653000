import VideoSchemeScript from 'components/Seo/SeoSchemeScripts/VideoSchemeScript/VideoSchemeScript';
import { useVideoThumbnailUrl } from 'features/cms/queries';
import { IVideo } from 'types/ContentfulTypes';
import { Video } from 'ui/Video/Video';
import { ContentfulVideoWrapper, PlayedHeader } from './ContentfulVideo.styled';

interface ContentfulVideoProps {
  className?: string;
  classNamePlayer?: string;
  content: IVideo;
  nested?: boolean;
}

const ContentfulVideo = ({ className, classNamePlayer, content, nested }: ContentfulVideoProps) => {
  const { title, videoUrl } = content.fields;

  const { data: thumbnail } = useVideoThumbnailUrl(videoUrl);

  return (
    <>
      <VideoSchemeScript
        title={title}
        url={videoUrl}
        createdAt={content.sys.createdAt}
        id={content.sys.id}
        thumbnailUrl={thumbnail}
      />
      <ContentfulVideoWrapper className={className} id={content.sys?.id} nested={nested}>
        {!nested && title && (
          <PlayedHeader textAlign="center" type="h2">
            {title}
          </PlayedHeader>
        )}
        <Video videoUrl={videoUrl} className={classNamePlayer} />
      </ContentfulVideoWrapper>
    </>
  );
};

export default ContentfulVideo;
