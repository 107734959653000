import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Typography from '../../../Typography/Typography';

interface ContentfulVideoWrapperProps {
  nested?: boolean;
}

export const ContentfulVideoWrapper = styled.div<ContentfulVideoWrapperProps>`
  ${({ nested }) =>
    !nested &&
    css`
      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          padding-left: 20%;
          padding-right: 20%;
        `,
      )};
    `}
`;

export const PlayedHeader = styled(Typography)`
  margin-bottom: ${spacer(150)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      margin-bottom: ${spacer(300)};
    `,
  )};
`;

interface PlayerWrapperProps {
  playing?: boolean;
}

export const PlayerWrapper = styled.div<PlayerWrapperProps>`
  position: relative;
  padding-top: ${variable('aspect-ratio-16-9-padding')};

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;
    border-radius: ${variable('border-radius')};
    opacity: ${({ playing }) => (playing ? 0 : 1)};
  }

  &:hover {
    .player {
      .player-icon {
        transform: scale(1.1);
      }
    }
  }
`;

export const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;

  .react-player__preview {
    border-radius: ${variable('border-radius')};
  }
`;
