import useDynamicScript from 'hooks/useDynamicScript';

interface VideoSchemeScriptProps {
  createdAt?: string;
  id?: string;
  thumbnailUrl?: string;
  title: string;
  url: string;
}

const VideoSchemeScript = ({ createdAt, id, thumbnailUrl, title, url }: VideoSchemeScriptProps) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    contentUrl: url,
    name: title,
    thumbnailUrl,
    uploadDate: createdAt,
  };

  useDynamicScript({
    innerHtml: JSON.stringify(structuredData),
    scriptId: `VideoSchemeScript${id ? `-${id}` : ''}`,
    type: 'application/ld+json',
  });

  return null;
};
export default VideoSchemeScript;
