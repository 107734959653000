import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';
import { useIsClient } from 'usehooks-ts';
import { cn } from 'utils/cn';

interface VideoProps extends ReactPlayerProps {
  className?: string;
  videoUrl: string;
}

const Video = ({ className, videoUrl }: VideoProps) => {
  const isClient = useIsClient();

  if (!isClient) {
    return null; // React player doesn't work on the server side (hydration issues)
  }

  return (
    <div className={cn('relative aspect-16/9', className)}>
      <ReactPlayer className="absolute left-0 top-0" url={videoUrl} controls height="100%" width="100%" />
    </div>
  );
};

Video.displayName = 'Video';

export { Video };
